<template>
  <VCard
    flat
    width="500px"
    color="transparent"
    class="white--text"
  >
    <VCardText
      style="border: 1px solid rgba(255, 255, 255, 0.25); border-radius: 16px;"
      :class="{
        'px-16': $vuetify.breakpoint.mdAndUp,
        'px-2': $vuetify.breakpoint.smAndDown,
        'py-6': true,
      }"
    >
      <VCardTitle class="white--text text-break">
        <span style="line-height: 27px; font-size: 18px; font-weight: 900;">
          Take these three steps to start your journey of becoming a young
          entrepenour
        </span>
      </VCardTitle>
      <VList
        three-line
        color="transparent"
      >
        <VListItem
          v-for="(item, key) in items"
          class="white--text"
          :key="key"
        >
          <VListItemAvatar
            size="66"
            :color="item.avatarBackgroundColor"
          >
            <VImg
              contain
              width="28"
              height="28"
              :src="item.avatar"
            />
          </VListItemAvatar>
          <VListItemContent>
            <VListItemTitle class="text-wrap">
              <span
                style="line-height: 27px; font-size: 18px; font-weight: 900;"
              >
                {{ item.title }}
              </span>
            </VListItemTitle>
            <VListItemSubtitle class="text-wrap">
              <span
                style="line-height: 22.4px; color: rgba(255, 255, 255, 0.85); font-size: 14px;"
              >
                {{ item.subTitle }}
              </span>
            </VListItemSubtitle>
          </VListItemContent>
        </VListItem>
      </VList>
    </VCardText>
    <VCardActions class="justify-center pt-8">
      <VBtn
        width="232px"
        color="#FFC100"
        class="text-capitalize rounded-lg px-3"
        style="box-shadow: 0px 3px 6px rgba(255, 193, 0, 0.2); font-size: 12px;"
        @click="$emit('explore')"
      >
        See what Alumni has created
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Register",
          subTitle:
            "Create your user profile and submit your registration form",
          avatar: require("../../images/onboarding/memo.png"),
          avatarBackgroundColor: "#C895CF",
        },
        {
          title: "First Lesson",
          subTitle: "Complete the intro to WeThrive lesson",
          avatar: require("../../images/onboarding/fire.png"),
          avatarBackgroundColor: "#F9D469",
        },
        {
          title: "Get matched with a coach",
          subTitle: "We will match you with a mentor after registration",
          avatar: require("../../images/onboarding/handshake.png"),
          avatarBackgroundColor: "#93D7A3",
        },
      ],
    };
  },
};
</script>

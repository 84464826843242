<template>
  <VRow :style="wrapperStyle">
    <VCol cols="12">
      <VRow
        :style="{
          padding: $vuetify.breakpoint.smAndDown ? '24px 16px' : '36px',
        }"
      >
        <VCol>
          <img
            height="44"
            :src="Logo"
          >
        </VCol>
      </VRow>
      <VRow
        class="text-center white--text"
        style="position: fixed; bottom: 0; width: 100%;"
        :style="getContentStyle"
      >
        <VCol>
          <h1
            class="pb-4"
            :style="{
              'font-size': $vuetify.breakpoint.smAndDown ? '30px' : '36px',
            }"
          >
            Welcome to WeThrive!
          </h1>
          <p
            class="mb-0"
            style="font-size: 20px; line-height: 28px; color: rgba(255, 255, 255, 0.85);"
          >
            We’re all about helping talented young people like you create real
            companies, that earn real money, and drive real impact.
          </p>
          <VBtn
            width="232px"
            color="#FFC100"
            class="text-capitalize mt-7"
            style="box-shadow: 0px 3px 6px rgba(255, 193, 0, 0.2);"
            @click="dialogs.learnMore = !dialogs.learnMore"
          >
            Learn More
          </VBtn>
        </VCol>
      </VRow>
      <VDialog
        width="475px"
        overlay-opacity="1"
        content-class="dialog-content"
        :dismissable="false"
        :persistent="false"
        :value="dialogs.learnMore"
        @click:outside="dialogs.learnMore = !dialogs.learnMore"
      >
        <LearnMore @explore="dialogs.explore = !dialogs.explore" />
      </VDialog>
      <VDialog
        width="475px"
        overlay-opacity="1"
        content-class="dialog-content"
        :dismissable="false"
        :persistent="false"
        :value="dialogs.explore"
        @click:outside="dialogs.explore = !dialogs.explore"
      >
        <Explore />
      </VDialog>
    </VCol>
  </VRow>
</template>

<script>
import BackgroundImage from "@/images/onboarding/main-bg.png";
import Logo from "@/images/onboarding/logo.png";
import LearnMore from "./LearnMore";
import Explore from "./Explore";

export default {
  components: {
    LearnMore,
    Explore,
  },
  data() {
    return {
      Logo,
      dialogs: {
        learnMore: false,
        explore: false,
      },
    };
  },
  computed: {
    wrapperStyle() {
      return {
        background: `url(${BackgroundImage}) no-repeat center center fixed !important`,
        backgroundSize: "cover",
        height: "100vh",
      };
    },
    getContentStyle() {
      return {
        background:
          "linear-gradient(180deg, rgba(37, 37, 38, 0) 0%, #252526 100%)",
        padding: this.$vuetify.breakpoint.smAndDown
          ? "48px 16px"
          : this.$vuetify.breakpoint.lgAndUp
          ? "54px 434px"
          : "54px 254px",
      };
    },
  },
};
</script>

<style lang="scss">
.dialog-content {
  box-shadow: none;
  overflow: hidden;
}
</style>

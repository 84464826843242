<template>
  <VRow class="text-center white--text">
    <VCol cols="12">
      <span style="line-height: 27px; font-size: 18px; font-weight: 900;">
        Click to explore companies WeThrive alumni have started! 😎
      </span>
    </VCol>
    <VCol cols="12">
      <VRow>
        <VCol
          v-for="(item, key) in items"
          :key="key"
          :cols="item.flex"
        >
          <VCard
            height="140"
            style="border-radius: 16px !important;"
            :ripple="false"
            @click="selectItem(key)"
          >
            <VImg
              height="100%"
              class="white--text align-top"
              :src="item.background"
            >
              <VCardTitle>
                <VChip :color="item.titleBackgroundColor">
                  {{ item.title }}
                </VChip>
                <VSpacer />
                <VAvatar
                  v-if="item.active"
                  color="#FFC100"
                  size="28"
                >
                  <VIcon color="white">
                    mdi-check
                  </VIcon>
                </VAvatar>
              </VCardTitle>
            </VImg>
          </VCard>
        </VCol>
      </VRow>
      <VRow
        v-if="showGetStarted"
        class="justify-center"
        style="position: relative; bottom: 70px;"
      >
        <VBtn
          width="232px"
          color="#FFC100"
          class="text-capitalize rounded-lg px-3"
          style="box-shadow: 0px 3px 6px rgba(255, 193, 0, 0.2); font-size: 12px;"
        >
          Get Started
        </VBtn>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
export default {
  data() {
    return {
      showGetStarted: false,
      items: [
        {
          title: "Fashion",
          titleBackgroundColor: "#80B2FC",
          background: require("../../images/onboarding/fashion.jpg"),
          active: false,
          flex: 12,
        },
        {
          title: "Wellness",
          titleBackgroundColor: "#D6A4DD",
          background: require("../../images/onboarding/wellness.jpg"),
          active: false,
          flex: 6,
        },
        {
          title: "Food",
          titleBackgroundColor: "#AAE0FF",
          background: require("../../images/onboarding/food.jpg"),
          active: false,
          flex: 6,
        },
        {
          title: "Chlothes",
          titleBackgroundColor: "#F9D469",
          background: require("../../images/onboarding/chlothes.jpg"),
          active: false,
          flex: 6,
        },
        {
          title: "Animals",
          titleBackgroundColor: "#93D7A3",
          background: require("../../images/onboarding/animals.jpg"),
          active: false,
          flex: 6,
        },
      ],
    };
  },
  methods: {
    selectItem(key) {
      this.showGetStarted = true;
      this.items.map((item) => (item.active = false));
      this.items[key].active = !this.items[key].active;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--absolute.v-btn--bottom {
  bottom: 50px;
}
</style>
